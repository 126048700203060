<template>
	<div>
		
		<div class="Top">
			
			<div class="OrderTypes">
				商品订单
			</div>
			
			<div class="Right">
				
				<span style="margin-left: 10px;">
					<label>类型</label>
					<el-select v-model="Search.IsPt" size="mini">
						<el-option :value="''" label="全部订单"></el-option>
						<el-option :value="'1'" label="拼团订单"></el-option>
						<el-option :value="'2'" label="普通订单"></el-option>
					</el-select>
				</span>
				
				<span style="margin-left: 10px;">
					<label>状态</label>
					<el-select v-model="Search.Status" size="mini">
						<el-option :value="-1" label="全部状态"></el-option>
						<el-option :value="50" label="待付款"></el-option>
						<el-option :value="60" label="待发货"></el-option>
					</el-select>
				</span>

				<span style="margin-left: 10px;">
					<label>买家账号</label>
					<el-input size="mini" v-model="Search.Keywords"></el-input>
				</span>
				
				<span style="margin-left: 10px;">
					<label>店铺名</label>
					<el-input size="mini" v-model="Search.Keywords"></el-input>
				</span>
				
				<span style="margin-left: 10px;">
					<label>订单编号</label>
					<el-input size="mini" v-model="Search.Keywords"></el-input>
				</span>

				
				<span style="margin-left: 10px;">
					<label style="color: rgba(0,0,0,0);">搜索</label>
					<el-button size="mini" type="primary" @click="StartSearching()">搜索</el-button>
				</span>
			</div>
			
		</div>
		
		
		<div class="TopTitle">
			<div class="C1">
				<span class="el-dropdown-link">
				  订单信息
				</span>
			</div>
			<div class="C2">数量</div>
			<div class="C3">单价</div>
			<div class="C4">小计</div>
			<div class="C5"></div>
			<div class="C6">订单状态</div>
			<div class="C7"></div>
			
		</div>
		
		<div class="Table" v-for="order in OrderList" :key="order.Id">
			
			<div class="Title">
				<ul class="Info">
					
					
					
					订单编号{{order.Id}}
					<span style="margin-left: 10px;">{{order.StatusName}}</span>
					
					<span style="margin-left: 10px;" v-if="order.Status == 50">
						<el-button round size="mini" @click="closeOrderCheck(order.Id)">关闭</el-button>
					</span>
				
				</ul>
				<ul class="OrderId">
					创建于 {{order.CreatedAt}} 
				</ul>
			</div>
			
			<div class="Grid" v-for="detail in order.Children" :key="detail.Id">
				
				<div class="C1">
					<div class="Left">
						<img :src="detail.GoodsThumb" />
					</div>
					<div class="Right">
						{{order.IsPt === 1 ? '[拼团]':''}}
						{{detail.GoodsName}}
						<li>{{detail.SkuName}}</li>
					</div>
				</div>
				<div class="C2">{{detail.Num}}{{detail.Unit}}</div>
				<div class="C3">{{detail.Price}}</div>
				<div class="C4">{{detail.TotalFee}}</div>
				<div class="C5"></div>
				<div class="C6"></div>
				<div class="C7">
					<span>
						
						<el-button size="mini" type="primary" @click="$Jump('/do/order/order_detail/'+order.Id)">详情</el-button>
					</span>
				</div>
				
			</div>
			
			
		</div>

		
		<div class="Page">
					<el-pagination
					  background
					  @current-change="PageChange"
					  layout="prev, pager, next"
					  :total="Total">
					</el-pagination>
				</div>

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Pagination} from 'element-ui'
	export default {
	  name: 'MyOrderList',
	  props: {
	  },
	  data() {
	      return {
			  Search:{
				Status:'',
				Page:1,
				PageSize:10,
				Keywords:'',
				ShopId:'',
				OrderId:'',
				IsPt:'',
			  },
			  OrderList:[],
			  Total:0,
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination
	  },
	  created() {
	  	this.StartSearching()
	  },
	  methods:{
		  SelectStateChange(_v){
			  this.SelectState = _v
		  },
		  PageChange(e){
			  this.Search.Page = e
			  this.getOrderList()
		  },
		   StartSearching(){
			  this.getOrderCount()
			  this.getOrderList()
		  },
		  getOrderList(){
			  let data = {
			  	Service:'Order',
			  	Class: 'Order',
			  	Action: 'List',
			  	Page:this.Search.Page,
				PageSize:this.Search.PageSize,
				Status:this.Search.Status,
				Keywords:this.Search.Keywords,
				ShopId:this.Search.ShopId,
				OrderId:this.Search.OrderId,
				IsPt:this.Search.IsPt,
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
			  	
			  	if(res.ErrorId != 0){
			  		this.$message(res.Msg)
			  		return
			  	}
			  	this.OrderList = res.Data.OrderList
			  	this.Page = res.Data.CurrentPage
			  	
			  })
			  .catch(function (response) {
			  	this.$message('网络请求错误')
			  })
		  },
		   getOrderCount(){
					  let data = {
						Service:'Order',
						Class: 'Order',
						Action: 'Count',
						Status:this.Search.Status,
						Keywords:this.Search.Keywords,
						ShopId:this.Search.ShopId,
						OrderId:this.Search.OrderId,
						IsPt:this.Search.IsPt,
					  }
					  this.$post(this.$store.getters.getApiHost,data)
					  .then((res) => {
						
						if(res.ErrorId != 0){
							this.$message(res.Msg)
							return
						}
						this.Total = res.Data.Count
						
					  })
					  .catch(function (response) {
						this.$message('网络请求错误')
					  })
		  },
		  closeOrderCheck(_id){
			  this.$confirm('是否确认关闭?', '提示', {
			  				confirmButtonText: '确定',
			  				cancelButtonText: '取消',
			  				type: 'warning'
			  				}).then(() => {
			  					this.closeOrder(_id)
			  				})
		  },
		  closeOrder(_id){
			  let data = {
				Service:'Order',
				Class: 'Order',
				Action: 'Update',
				Id:_id,
				Status:10,
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
				
				if(res.ErrorId != 0){
					this.$message(res.Msg)
					return
				}
				
				this.$message.success('订单已关闭')
				
			  })
			  .catch(function (response) {
				this.$message('网络请求错误')
			  })
		  }
	  }
	}
</script>

<style scoped>
	.Top{
		display: flex;
		margin: 20px 20px 0px 20px;
		background-color: #FFFFFF;
		align-items: center;
		padding: 25px;
		
	}
	.Top .OrderTypes{
		flex: 1;
	}
	.OrderTypes span{
		margin-right: 15px;
		cursor: pointer;
	}
	.OrderTypes span.On{
		text-decoration: underline;
	}
	.Top .Right{
		display: flex;
	}
	.Top .Right li{
		margin-left: 10px;
	}
	.Top .Right label{
		display: block;
		font-size: 0.9rem;
		color: rgba(0,0,0,0.4);
		margin-bottom: 5px;
	}
.TopTitle{
		padding: 10px 25px;
		display: flex;
		color: #999999;
		font-size: 0.9rem;
		margin: 5px 20px 0px 20px;
	}
	
	.C1{
		flex: 1;
		display: flex;
		align-items: center;
	}
	.C1 .Left{
		width: 120px;
	}
	.C1 .Left img{
		width: 80px;
		height: 60px;
	}
	.C1 .Right{
		flex: 1;
	}
	.C1 .Right li{
		width: 100%;
		text-align: right;
		font-size: 0.9rem;
		color: rgba(0,0,0,0.3);
		margin-top: 10px;
	}
	.C2,.C3,.C4,.C5,.C6{
		width: 120px;
		text-align: center;
	}
	.C7{
		width: 50px;
		text-align: center;
		margin-right: 10px;
	}
	.C7 span{
		padding: 5px 10px;
		cursor: pointer;
	}
	.C7 span:hover{
		color: rgba(228,0,0,1);
	}
	
	.Table{
		margin: 20px;
		padding: 20px;
		background-color: #FFFFFF;
	}
	.Table .Title{
		display: flex;
		margin-bottom: 15px;
		color: rgba(0,0,0,0.3);
		font-size: 0.9rem;
	}
	.Table .Title .Info{
		flex: 1;
	}
	.Table .Title .OrderId{
		width: 200px;
		text-align: right;
	}
	.Table .Grid{
		display: flex;
		padding: 10px 0px;
		border-top: 1px solid rgba(0,0,0,0.04);
		align-items: center;
	}

</style>
